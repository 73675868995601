import React, {useEffect, useState} from 'react';
import classes from "./block3.module.css";

const Block3 = ({blocks}) => {
    const [texts, setText] = useState([
        {id:0, name: 'устал<br/>терять депозит', text: 'Правильный риск- и мани- менеджмент, регулярное обучение<br/>и практика исправят положение!'},
        {id:1, name: 'Хочешь<br/>улучшить навыки', text: 'Нам есть, что рассказать. Даже, если вы уже опытный трейдер.'},
        {id:2, name: 'готов<br/>к чему-то большему', text: 'Рынок многогранен,<br/> самостоятельное нахождение<br/>в рыне лишает большого количества преимуществ, поэтому необходимо обзавестись большой командой, которая всегда придет на помощь.'}
    ])

    useEffect(()=>{
        setText([
            {id:0, name: blocks.block4?.title1, text: blocks.block4?.text1},
            {id:1, name: blocks.block4?.title2, text: blocks.block4?.text2},
            {id:2, name: blocks.block4?.title3, text: blocks.block4?.text3}
        ])
    },[blocks])
    return (
        <div className={classes._}>
            <div className={classes.block_}>
                <div className={classes.name}>тебе в smart mania<br/>если ты:</div>
                <div className={classes.block}>
                    {texts.map(item=>
                        <div key={item.id} className={classes.item}>
                            <div className={classes.header} dangerouslySetInnerHTML={{__html: item.name}}></div>
                            <div className={classes.text} dangerouslySetInnerHTML={{__html: item.text}}></div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Block3;