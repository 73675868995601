import React, {useEffect, useRef, useState} from 'react';
import classes from "./block4.module.css";
import {useResize} from "../../../hooks/useResize";
import { useGesture } from '@use-gesture/react'
import { useSpring, animated } from '@react-spring/web'
import ButtonTariff from "../../../common/buttonTariff/ButtonTariff";

const Block4 = ({blocks}) => {
    const [{x}, api] = useSpring(() => ({ x: 0}))
    const [x0, setX0] = useState(0)
    const [step, setStep] = useState(1)
    const [allStep, setAllStep] = useState(10)
    const [countStep, setCountStep] = useState(1)
    const refButtonRight = useRef(null)
    const refButtonLeft = useRef(null)
    const bind = useGesture({
        onDrag: ({ down, active, offset: [x], movement: [xx] }) => {
            if(xx === 0) return
            if(xx+x0 < 0 && xx+x0 > (1-allStep) * 480){
                api.start({
                    to: {
                        x: x0+xx,
                    },
                })
                if(!active){
                    let countStepTemp = Math.abs(Math.trunc(xx/480))+1
                    setCountStep(countStepTemp)
                    setTimeout(()=> {
                        if(xx < 0){
                            api.start({
                                to: {
                                    x: x0-480*countStepTemp,
                                },
                            })
                            setX0(x0-480*countStepTemp)
                            setStep(step+countStepTemp)
                            setStopAnim(false)
                            refButtonRight.current?.click();
                            setTimeout(()=>setStopAnim(true),700)
                        }else{
                            api.start({
                                to: {
                                    x: x0+480*countStepTemp,
                                },
                            })
                            setX0(x0+480*countStepTemp)
                            setStep(step-countStepTemp)
                            setStopAnim(false)
                            refButtonLeft.current?.click();
                            setTimeout(()=>setStopAnim(true),700)
                        }

                    },1)

                }
            }
            if(xx+x0 > 0){
                setCountStep(step - 1)
                if(step - 1 > 0)
                    setTimeout(()=> {
                        setX0(0)
                        api.start({
                            to: {
                                x: 0,
                            },
                        })
                        setStep(1)
                        setStopAnim(false)
                        refButtonLeft.current?.click();
                        setTimeout(()=>setStopAnim(true),700)
                    },1)
            }
            if(xx+x0 < (1-allStep) * 480){
                setCountStep(allStep - step)
                if(allStep - step > 0)
                    setTimeout(()=> {
                        setX0((1-allStep) * 480)
                        api.start({
                            to: {
                                x: (1-allStep) * 480,
                            },
                        })
                        setStep(allStep)
                        setStopAnim(false)
                        refButtonRight.current?.click();
                        setTimeout(()=>setStopAnim(true),700)
                    },1)
            }
        }
    })

    const [row, setRow] = useState('')
    const [column, setColumn] = useState('')
    const [rowLine, setRowLine] = useState('')
    const {width} = useResize()
    const [position, setPosition] = useState(width*0.4)
    const [allText, setAllText] = useState([])
    const pathMas = [350,1170,1840,2340,2870,3510,4110,4720,5240,6000]
    const [stopAnim, setStopAnim] = useState(true)

    useEffect(()=>{
        let temp = ''
        for(let i=0; i < 22; i++)
            temp += 'M0 '+(i*34+25)+'H8000 '
        setRow(temp)
        temp = ''
        for(let i=0; i < 100; i++)
            temp += 'M'+(i*80+5)+' 0V1000 '
        setColumn(temp)
        temp = ''
        for(let i=0; i < 280; i++){
            if((i-2) % 9 === 0){
                temp += 'M'+(i*27)+' 704v-32 '
            }else{
                temp += 'M'+(i*27)+' 704v-14 '
            }
        }
        setRowLine(temp)
        temp = []
        temp.push({id:1, position:position, text: "Теория", allText: "Теория - это фундаментальный шаг/nв развитии трейдера, именно /nна полученных теоретических знаниях /nбудет выстраиваться ваша тактика /nработы."})
        temp.push({id:2, position:position+480, text: "Методички", allText: "Наша школа подготовила методический /nматериал, чтобы проще усваивать /nинформацию."})
        temp.push({id:3, position:position+480*2, text: "Домашние задания", allText: "Важный элемент в обучении, который /nпозволит вам понять свою успеваемость /nи совместно с преподавателями сделать /nработу над ошибками."})
        temp.push({id:4, position:position+480*3, text: "QA Сессии", allText: "Это один из эффективных способов /nулучшения своих навыков. На онлайн /nсессиях всегда есть возможность /nполучить ответы на все вопросы."})
        temp.push({id:5, position:position+480*4, text: "Практические занятия", allText: "На практических занятиях/nмы разрабатываем вашу стратегию /nторговли и тщательно /nподготавливаемся к работе с рынком."})
        temp.push({id:6, position:position+480*5, text: "Видео уроки", allText: "У современного человека не всегда есть /nвремя присутствовать на онлайн уроках, /nименно поэтому мы подготовили нашу /nплатформу, где находится весь /nпройденный материал, методички, /nзапись уроков и домашние задания."})
        //temp.push({id:7, position:position+480*6, text: "7 этап", allText: "Учись, практикуйся, развивайся./nАкадемия - это не просто/nобучающий курс. Здесь каждый/nучастник заинтересован в развитии/nвсей экосистемы."})
        //temp.push({id:8, position:position+480*7, text: "8 этап", allText: "Учись, практикуйся, развивайся./nАкадемия - это не просто/nобучающий курс. Здесь каждый/nучастник заинтересован в развитии/nвсей экосистемы."})
        //temp.push({id:9, position:position+480*8, text: "9 этап", allText: "Учись, практикуйся, развивайся./nАкадемия - это не просто/nобучающий курс. Здесь каждый/nучастник заинтересован в развитии/nвсей экосистемы."})
        //temp.push({id:10, position:position+480*9, text: "10 этап", allText: "Учись, практикуйся, развивайся./nАкадемия - это не просто/nобучающий курс. Здесь каждый/nучастник заинтересован в развитии/nвсей экосистемы."})
        setAllText(temp)
        setAllStep(temp.length)
    },[position])

    useEffect(()=>{
        let temp = []
        for(let i=0; i<blocks.block5?.data.length;i++){
            temp.push({id:i+1, position:position+480*i, text: blocks.block5?.data[i].title, allText: blocks.block5?.data[i].text.substr(3,blocks.block5?.data[i].text.length-7)})
        }
        //temp.push({id:1, position:position, text: "Теория", allText: "Теория - это фундаментальный шаг/nв развитии трейдера, именно /nна полученных теоретических знаниях /nбудет выстраиваться ваша тактика /nработы."})
        setAllText(temp)
        setAllStep(temp.length)
    },[blocks])

    useEffect(()=>{
        if(width >= 900)
            setPosition(width*0.4)
        if(width < 900)
            setPosition(width*0.2)
        if(width < 650)
            setPosition(width*0.1)
    },[width])

    function nextStep(){
        if(step < 10){
            api.start({
                to: {
                    x: x0-480,
                },
            })
            setX0(x0-480)
            setStopAnim(false)
            setStep(step+1)
            setTimeout(()=>setStopAnim(true),700)
        }
    }

    function prevStep(){
        if(step > 1){
            api.start({
                to: {
                    x: x0+480,
                },
            })
            setX0(x0+480)
            setStopAnim(false)
            setStep(step-1)
            setTimeout(()=>setStopAnim(true),700)
        }
    }

    return (
        <div className={classes._}>
            <svg className={classes.fon} width="100%" height="705" viewBox={`0 0 ${width} 705`} fill="none" xmlns="http://www.w3.org/2000/svg">
                <animated.rect className={classes.fon2} {...bind()} style={{x}} width="10000" height="705" fill={'rgba(0,0,0,0)'}></animated.rect>
            </svg>
            <svg width="100%" height="750" viewBox={`0 0 ${width} 750`} fill="none" xmlns="http://www.w3.org/2000/svg">
                <animated.g {...bind()} style={{x}} id="backdrop" className={classes.textAllStepGroup}>
                    <path d={row+' '+column} stroke="#180F1F"/>
                    {allText.map(item=>
                        <path key={item.id} d={row+' '+column} stroke="url(#radial_1)" transform={`translate(${(item.id-1)*480} 0)`} className={classes.pathAnim} strokeOpacity={step===item.id?0.8:0}/>
                    )}
                </animated.g>
                <defs>
                    <radialGradient id="radial_1" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform={`translate(${position} 315) rotate(90) scale(435 518.416)`}>
                        <stop stopColor="white"/>
                        <stop offset="0.180759" stopColor="white" stopOpacity="0.3"/>
                        <stop offset="0.316842" stopColor="white" stopOpacity="0.08"/>
                        <stop offset="0.82131" stopColor="white" stopOpacity="0"/>
                    </radialGradient>
                </defs>
                <animated.g {...bind()} style={{x}}>
                    <path className={classes.textAllStepGroup} width="1356" height="407" transform={`translate(${position-220} 195)`} d={"M1 267.5L49 165.5L81 203L132.5 172.5L159.5 183L230 155L270 70L291 131.5L317 98.5L350 172.5L426 124.5L459 165.5L539 70L581.5 183L609.5 155L640 195L707.5 154L784.5 131.5L862 1L890.5 46.5L948 23L1147 202L1187 154L1245 183L1497 124.5L1667 154L1743 171L1934.5 131L1948.5 146L1967.5 108.5 L2060.5 166.5 L2147 154"+
                        (allStep>=6?"L2228.5 75.5L2332.5 234.5L2425 271.5L2626.5 154":"")+
                        (allStep>=7?"L2671 123L2729.5 182 L2765 154L2782.5 164L2876.5 65 L2899 75.5L2932 65L3011 108 L3108 154":"")+
                        (allStep>=8?"L3169 173L3318.5 114L3329 123L3371 50.5L3385.5 55.5L3537.5 75.5L3552.5 154H3588":"")+
                        (allStep>=9?"L3890 123L4023.5 201L4068 154":"")+
                        (allStep===10?"L4153 179.5L4175.5 165.5 L4228.5 100 L4238 108L4285.5 79.5L4318 108L4397.5 62L4482 238L4547 154":"")}
                          stroke="#7D648F" strokeDasharray="10 10"/>
                    <path className={classes.pathAnim} width="1356" height="407" transform={`translate(${position-220} 195)`} d="M230 155L270 70L291 131.5L317 98.5L350 172.5L426 124.5L459 165.5L539 70L581.5 183L609.5 155L640 195L707.5 154L784.5 131.5L862 1L890.5 46.5L948 23L1147 202L1187 154L1245 183L1497 124.5L1667 154L1743 171L1934.5 131L1948.5 146L1967.5 108.5L2060.5 166.5L2147 154L2228.5 75.5L2332.5 234.5L2425 271.5L2626.5 154L2671 123L2729.5 182L2765 154L2782.5 164L2876.5 65L2899 75.5L2932 65L3011 108L3108 154L3169 173L3318.5 114L3329 123L3371 50.5L3385.5 55.5L3537.5 75.5L3552.5 154H3588L3890 123L4023.5 201L4068 154L4153 179.5L4175.5 165.5L4228.5 100L4238 108L4285.5 79.5L4318 108L4397.5 62L4482 238L4547 154"
                          stroke="#AA55EC" strokeWidth="3" strokeDasharray="10000" strokeDashoffset={10000-pathMas[step-1]+330}/>
                    <path transform={`translate(${position-221} 348)`} d="M2 114.5L50 12.5L82 50L133.5 19.5L160.5 30L231 2" stroke="url(#paint0_linear_1_256)" strokeWidth="3"/>
                    <defs>
                        <linearGradient id="paint0_linear_1_256" x1="105" y1="37.5" x2="20" y2="106.5" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#AA55EC"/>
                            <stop offset="1" stopColor="#AA55EC" stopOpacity="0"/>
                        </linearGradient>
                    </defs>
                    {allText.map(item=>
                        <g key={item.id} className={classes.textAllStepGroup} filter={`url(#blurMe${item.id})`}>
                            <text className={classes.textAllHeader} x={item.position} y="290" fill={'#FFF'}>{item.text}</text>
                        </g>
                        )}
                </animated.g>
               {allText.map(item=>
                    <filter key={item.id} id={"blurMe"+item.id}>
                        <feGaussianBlur in="SourceGraphic" stdDeviation={item.id===step?0:3}>
                            <animate attributeName="stdDeviation"
                                     from="0" to="3" dur=".7s" begin={(step===item.id) && step<10?"buttonRight.click;buttonRight2.click":""} repeatCount="1" fill={'freeze'}/>
                            <animate attributeName="stdDeviation"
                                     from="3" to="0" dur=".7s" begin={(step+countStep)===item.id?"buttonRight.click;buttonRight2.click":""} repeatCount="1" fill={'freeze'}/>
                            <animate attributeName="stdDeviation"
                                     from="0" to="3" dur=".7s" begin={(step===item.id) && step>1?"buttonLeft.click;buttonLeft2.click":""} repeatCount="1" fill={'freeze'}/>
                            <animate attributeName="stdDeviation"
                                     from="3" to="0" dur=".7s" begin={(step-countStep)===item.id?"buttonLeft.click;buttonLeft2.click":""} repeatCount="1" fill={'freeze'}/>
                        </feGaussianBlur>
                    </filter>
                )}
                <foreignObject>
                    <button id="buttonRight2" ref={refButtonRight}>Button1</button>
                    <button id="buttonLeft2" ref={refButtonLeft}>Button2</button>
                </foreignObject>
                <path className={classes.str+' '+(step===1 && classes.strOff)} transform={`translate(${width>650?width*0.15:10} 715)`}  d="M0.939339 10.9393C0.353552 11.5251 0.353552 12.4749 0.939339 13.0607L10.4853 22.6066C11.0711 23.1924 12.0208 23.1924 12.6066 22.6066C13.1924 22.0208 13.1924 21.0711 12.6066 20.4853L4.12132 12L12.6066 3.51472C13.1924 2.92893 13.1924 1.97918 12.6066 1.3934C12.0208 0.80761 11.0711 0.80761 10.4853 1.3934L0.939339 10.9393ZM29 10.5L2 10.5L2 13.5L29 13.5L29 10.5Z" fill="white"/>
                <path className={classes.str+' '+(step===10 && classes.strOff)} transform={`translate(${width>650?(width*0.85-27):width-37} 715)`} d="M28.0607 13.0607C28.6464 12.4749 28.6464 11.5251 28.0607 10.9393L18.5147 1.3934C17.9289 0.807611 16.9792 0.807611 16.3934 1.3934C15.8076 1.97919 15.8076 2.92893 16.3934 3.51472L24.8787 12L16.3934 20.4853C15.8076 21.0711 15.8076 22.0208 16.3934 22.6066C16.9792 23.1924 17.9289 23.1924 18.5147 22.6066L28.0607 13.0607ZM0 13.5H27V10.5H0V13.5Z" fill="white"/>
                <rect id="buttonLeft" onClick={prevStep} className={classes.buttonLeftRect} width="30" height="30" transform={`translate(${width>650?width*0.15:10} 715)`} fill={'rgba(0,0,0,0)'}></rect>
                <rect id="buttonRight" onClick={nextStep} className={classes.buttonLeftRect} width="30" height="30" transform={`translate(${width>650?(width*0.85-27):width-37} 715)`} fill={'rgba(0,0,0,0)'}></rect>
                <defs>
                    <filter id="filter0_dddd_108_331" x="0" y="0" width="130" height="130" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                        <feOffset dy="4"/>
                        <feGaussianBlur stdDeviation="2"/>
                        <feComposite in2="hardAlpha" operator="out"/>
                        <feColorMatrix type="matrix" values="0 0 0 0 0.0470588 0 0 0 0 0.0352941 0 0 0 0 0.0588235 0 0 0 0.6 0"/>
                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_155_276"/>
                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_155_276" result="shape"/>
                    </filter>
                    <linearGradient id="paint0_linear_108_331" x1="65" y1="50" x2="65" y2="80" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#F6EBFF"/>
                        <stop offset="0.34" stopColor="#A046E7"/>
                        <stop offset="1" stopColor="#451A67"/>
                    </linearGradient>
                    <radialGradient id="paint1_radial_108_331" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(63.5556 74.5) rotate(-80.3556) scale(17.2437 19.7835)">
                        <stop offset="0.688164" stopColor="white" stopOpacity="0"/>
                        <stop offset="0.880574" stopColor="white"/>
                    </radialGradient>
                </defs>
                <defs>
                    <radialGradient id="paint0_radial_155_284" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(50 50) rotate(90) scale(50)">
                        <stop stopColor="#AA55EC"/>
                        <stop offset="1" stopColor="#AA55EC" stopOpacity="0">
                            <animate attributeName="offset"
                                     from="0.25" to="1" dur=".7s" begin='0s' repeatCount="indefinite"/>
                        </stop>
                    </radialGradient>
                </defs>
            </svg>
            <div className={classes.line}></div>
            <div className={classes.headerText}>программа обучения</div>
            <svg className={classes.lineSVG} width="100%" height="705" viewBox={`0 0 ${width} 705`} fill="none" xmlns="http://www.w3.org/2000/svg">
                <animated.g {...bind()} style={{x}} className={classes.textAllStepGroup}>
                    <path d="M0 703H8000" stroke="white" strokeWidth="2"/>
                    <path d={rowLine} stroke="white" strokeWidth="2"/>
                    {allText.map((item, number)=>
                        <text key={item.id} className={classes.textAllStep} x={item.position} y="664" fill={'#FFF'}>{number+1} Этап</text>
                    )}
                    {allText.map(item=>
                        <g key={item.id} className={classes.textAllStepGroup} filter={`url(#blurMe${item.id})`}>
                            <circle transform={`translate(${item.position - 35} 295)`} opacity="0.6" cx="50" cy="50" r="50" fill={(step===item.id && stopAnim) ?"url(#paint0_radial_155_284)":''}/>
                            <g className={classes.pointFilter+' '+(step!==item.id && classes.pointFilterGrey)+' '+(step===item.id && stopAnim && classes.pointPulse)} transform={`translate(${item.position - 50} 280)`}>
                                <circle cx="65" cy="65" r="15" fill="url(#paint0_linear_108_331)"></circle>
                                <ellipse cx="65" cy="69" rx="13" ry="9" fill="url(#paint1_radial_108_331)" fillOpacity="0.3"/>
                            </g>
                            <text className={classes.textAll} x={item.position} y="340" fill={'#FFF'}>
                                {item.allText.split('/n').map((i,n)=>
                                    <tspan key={n} x={item.position} y={405+20*n}>{i}</tspan>)}
                            </text>
                        </g>
                    )}
                </animated.g>
            </svg>
            <div className={classes.button}>
                <ButtonTariff/>
            </div>
        </div>
    );
};

export default Block4;