import React from 'react';
import classes from "./block2.module.css";
import ButtonMain from "../../../common/buttonMain/buttonMain";
import logo from '../../../../images/png/CMicon.gif'

const Block2 = ({blocks, links}) => {
    return (
        <div className={classes._}>
            <div className={classes.block_}>
                <div className={classes.name}>
                    о школе<br/>smart mania
                </div>
                <div className={classes.block}>
                    <div className={classes.text1} dangerouslySetInnerHTML={{__html: blocks.block3?.text}}></div>
                    <div className={classes.block2}>
                        <div className={classes.block2_1}>
                            <div className={classes.text2}>напиши нам прямо сейчас<br/>ответим на любой вопрос:</div>
                            <ButtonMain text={'НАПИСАТЬ НАМ'} color={0} str={true} click={()=>window.open(links.link)}/>
                        </div>
                        <div className={classes.logoBlock}>
                            <img src={logo} alt={''} className={classes.logo}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Block2;