import React from "react";
import classes from './Crums.module.scss'
import { Link } from 'react-router-dom'

function Crums({ data }) {
    return (
        <div className={classes._}>
            <div className={classes.block_ }>
                <span>
                    <Link to='/'>
                        главная
                    </Link>
                </span>
                {data.map((element, index) => {
                    if (data.length > index + 1) {
                        return (
                            <div key={index}>
                                <div>
                                    <Link to={'/' + element[0]} className={classes.crumsActive}>
                                        {element[1]}
                                    </Link>
                                </div>
                            </div>
                        )
                    } else {
                        return (
                            <span key={index + 1} className={classes.limited}>
                                {element[1]}
                            </span>
                        )
                    }
                })}
            </div>
        </div>

    )
}

export default Crums