import React, {useEffect, useRef, useState} from 'react';
import classes from "./faq.module.css";

const ItemAccordion = ({text, isOpen}) =>{
    const refItem = useRef(null)
    const styles = [{
        transform: 'rotateX(0deg)',
        height:refItem?.current?.scrollHeight,
        marginTop: '15px',
        marginBottom: 0//window.innerWidth > 768 ? '3.5rem' : '10rem'
    },{
        height:0,
        transform: 'rotateX(90deg)',
        marginTop: 0,
        marginBottom: 0
    }]
    return (
        <div ref={refItem} className={classes.contentWrap} style={styles[isOpen?1:0]} dangerouslySetInnerHTML={{__html: text}}></div>
    );
}

const Accordion = ({ items }) => {
    const [openIndex, setOpenIndex] = useState(null);

    return (
        <div className={classes.Accordion}>
            {items.map(item =>
                <div key={item.id}
                    className={classes.header}
                    onClick={() => {
                        setOpenIndex(openIndex === item.id ? null : item.id);
                    }}
                >
                    <div className={classes.icon}>
                        <div className={classes.horizontal}></div>
                        <div className={`${classes.vertical} ${openIndex === item.id && classes.verticalOff}`}></div>
                    </div>
                    <div className={classes.titleBlock}>
                        <div className={`${classes.title} ${openIndex === item.id && classes.activeTitle}`} dangerouslySetInnerHTML={{__html: item.title}}></div>
                        <ItemAccordion text={item.content} isOpen={openIndex !== item.id}></ItemAccordion>
                    </div>
                </div>
            )}
        </div>
    );
}

const Faq = ({blocks}) => {
    const [items, setItems] = useState([
        {id:0, title:'У меня нет возможности оплатить тариф. Что делать?', content:'Можно — многие наши ученики так и делают. На обучение понадобится в среднем два часа в день. Тебе не придется собирать пазл из тысячи деталей по всему интернету, так как в Криптомании есть вся информация, которая необходима для заработка.  А большую часть процессов, которые приносят финансовые результаты — упростишь с помощью наших инструментов и таблиц. Сможешь зарабатывать параллельно с основной деятельностью.'},
        {id:1, title:'Сколько длится обучение?', content:'Можно — многие наши ученики так и делают. На обучение понадобится в среднем два часа в день. Тебе не придется собирать пазл из тысячи деталей по всему интернету, так как в Криптомании есть вся информация, которая необходима для заработка.  А большую часть процессов, которые приносят финансовые результаты — упростишь с помощью наших инструментов и таблиц. Сможешь зарабатывать параллельно с основной деятельностью.'},
        {id:2, title:'На какой результат можно рассчитывать после обучения?', content:'Можно — многие наши ученики так и делают. На обучение понадобится в среднем два часа в день. Тебе не придется собирать пазл из тысячи деталей по всему интернету, так как в Криптомании есть вся информация, которая необходима для заработка.  А большую часть процессов, которые приносят финансовые результаты — упростишь с помощью наших инструментов и таблиц. Сможешь зарабатывать параллельно с основной деятельностью.'},
        {id:3, title:'Почему Smart Money?', content:'Можно — многие наши ученики так и делают. На обучение понадобится в среднем два часа в день. Тебе не придется собирать пазл из тысячи деталей по всему интернету, так как в Криптомании есть вся информация, которая необходима для заработка.  А большую часть процессов, которые приносят финансовые результаты — упростишь с помощью наших инструментов и таблиц. Сможешь зарабатывать параллельно с основной деятельностью.'},
    ])
    useEffect(()=>{
        let temp=[]
        for(let i=0; i<blocks.faq?.data.length; i++){
            temp.push({id:i, title:blocks.faq?.data[i].request, content:blocks.faq?.data[i].response})
        }
        setItems(temp)
    },[blocks])
    return (
        <div className={classes._}>
            <div className={classes.block}>
                <div className={classes.name}>ЧАСТО ЗАДАВАЕМЫЕ ВОПРОСЫ</div>
                <Accordion items={items}/>
            </div>
        </div>
    );
};

export default Faq;