import React from 'react';
import classes from "./ButtonCategoty.module.scss";

function ButtonTariff(props) {
    function activeButton() {
        return (
            <a className={props.active ? classes.__ : classes._}>
                <div className={classes.text}>{props.children}</div>
            </a>)
    }
    return (
        activeButton()
    );
};

export default ButtonTariff;